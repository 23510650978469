
import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"

class Sodexo extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Sodexo" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-sodexo-site inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center">
                
                </h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://sodexo.ma" title="" target="_blank">
                                <img src="/img/clients/sodexo-site.png" alt="" className="project__logo" />
                            </a>
                        </p>

                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">SODEXO MAROC.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://sodexo.ma" title="PRECISION SYSTEMS" target="_blank">sodexo.ma</a>
                            </span>
                            <span className="project__detail">Year 2017</span>
                        </p>
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading m-top">SODEXO MAROC.</h1>
                        <p className="article__para">
                            Plus qu’ un site institutionnel, Sodexo.ma a été pensé de façon a ce qu’ il soit orienté résultat (conversions).
                            Étant équipé des derniers standards en termes de technologies et de sécurité, le code a été parfaitement optimisé SEO et performance, le contenu adapté à la cible B2B et les appel à l 'actions (CTA) ont été bien étudiés.
                        </p>
                    </p>

                </div>


                <p className="article__belt" style={{ marginTop: "0" }}>
                    <img className="article__belt-image" src="/img/projects/sodexo-site/banner-project.png" alt="mobile-apps" />
                </p>

                <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            
                                < img src = "/img/projects/sodexo-site/anime-services3.gif"
                                alt = ""
                                className = "project__gif" / >

                               
                            
                        </p>
                       

                        
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading">Clients mobile</h1>
                        <p className="article__para">
                            Les clients mobile sont servis par la technologie Accelerated Mobile Pages(AMP) qui est une open source proposée et soutenue par Google permettant un rendu ultra rapide.
                            Ainsi une page AMP sera privilégiée par Google en lui accordant un meilleur positionnement qu 'une page faite par une technologie web traditionnel.
                        </p>
                       
                    </p>

                </div>


            

                    <p className="article__belt w-70" style={{ marginTop: "0" }}>
                        <img className="article__belt-image" src="/img/projects/sodexo-site/amp.png" alt="mobile-apps" />

                   
                    </p>

                 {/* <div className="article__body article__body--has-extra">

                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            
                                < img src = "/img/projects/sodexo-site/anime-services1.gif"
                                alt = ""
                                className = "project__gif" / >
                           
                        </p>

                       
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading">Esprit de service</h1>
                        <p className="article__para">
                            Grâce à son module de géolocalisation, l’application mobile Sodexo permet de localiser rapidement sur une carte les commerces affiliés à proximité de l’utilisateur. 
                        </p>
                    </p>

                </div> */}

                {/* <div className="article__body article__body--has-extra">
                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            
                                < img src = "/img/projects/sodexo-site/anime-services2.gif"
                                alt = ""
                                className = "project__gif" / >
                            
                        </p>

                       
                    </aside>

                    <p className="v-center">
                        <h1 className="article__heading">Esprit d'équipe</h1>
                        <p className="article__para">
                            Grâce à son module de géolocalisation, l’application mobile Sodexo permet de localiser rapidement sur une carte les commerces affiliés à proximité de l’utilisateur. 
                        </p>
                    </p>

                </div> */}


               


                
                {/* <div className="article__body">
                    <h2 className="article__heading">Le challenge</h2>
                    <p className="article__para">
                        Concevoir une application intuitive, avec un design ergonomique et moderne. <br />
                        Nous avons adopté une approche de conception mobile-first.Grâce à cette approche, le design s’adapte même au plus petit des appareils mobiles, puis généralisé à des écrans plus grand jusqu’à l’interface desktop.<br />
                    </p>
                </div> */}

                {/* <p className="article__belt inuit-u-mb0">
                    < img className = "article__belt-image"
                    src = "/img/projects/sodexo-site/anime-services.gif"
                    alt = "branding" / >
                </p> */}

                {/* <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/sodexo-site/uxinfoaffilie.png" alt="branding" />
                </p>
                <br /><br /> */}
            </article>
            <Realisation/>

            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default Sodexo
